import { useState, useEffect } from "react";
import WebFont from "webfontloader";
import styled from "styled-components";
import Top from "./components/Top";
import Tiles from "./components/Tiles";
import Alert from "./components/Alert";
import Welcome from "./pages/Welcome";
import VitalsForm from "./pages/VitalsForm";
import VaccinesForm from "./pages/VaccinesForm";

let S = {};
S.Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

S.Content = styled.div`
  margin-top: 50px;
  width: 85%;
  padding: 25px;
  background-color: #fff;
  border: 1px solid var(--border);
`;

const App = () => {
  const [status, setStatus] = useState(3);
  const [form, setForm] = useState("");

  /*
    0 - error
    1 - vitals or vaccines submitted successfully
    2 - patient entered
    3 - welcome
  */

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Open Sans:n4"],
      },
    });
  }, []);

  const handleStatus = (status) => {
    setStatus(status);
    if (status === 1 || status === 3) {
      setForm("");
    }
  };

  return (
    <S.Wrapper>
      <Top />
      <S.Content>
        <Welcome status={status} update={handleStatus} />
        {(status === 1 || status === 2) && (
          <Tiles form={form} update={setForm} />
        )}
        {form === "vitals" ? (
          <VitalsForm update={handleStatus} />
        ) : form === "vaccines" ? (
          <VaccinesForm update={handleStatus} />
        ) : null}
        {status === 1 && (
          <Alert
            data={{
              type: "success",
              text: "Your data has been saved, thank you!",
            }}
          />
        )}
      </S.Content>
    </S.Wrapper>
  );
};

export default App;
